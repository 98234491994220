import { useFormik } from "formik";

export function useEditNFT(submit, initialVal, cb) {
  const validation = (values) => {
    let errors = {};
    if (!values.price || values.price === "") {
      errors.price = "Please enter price";
    }
    if (!values.price || values.price === "" || values.price <= 0) {
      errors.price = "Please enter valid price";
    }
    if (!values.title || values.title === "") {
      errors.title = "Please enter title";
    }
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    token: "",
    is_listed: 1,
    price: "0.02",
    title: "",
    description: "",
  };

  const formik = useFormik({
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    enableReinitialize: true,
    validate: validation
  });
  return { formik };
}
