import { Contract } from '@ethersproject/contracts';
import { parseEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
// import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import contract from '../../Configs/Contract';
import UseUtil from '../../Contexts/Util/useUtil';
import { useEditNFT } from '../../Hooks/EditNftDetails';
import {
  editNftDetails,
  getNFTDetails,
  getMarketPrice,
  listNftTransaction
} from '../../lib/NFT/index';
import { getProfile } from '../../lib/User';

// import Group from "../FormsElements/Group";
import Button from '../Button';
import Input from '../FormsElements/Input';
import SwitchField from '../FormsElements/Switch';
import TextArea from '../FormsElements/textArea';
import { Label } from '../FormsElements/utils';
import { default as classess, default as styles } from './styles.module.scss';
import blockChainData from '../../Hooks/blockChainData';
import getChainID from '../../Hooks/getChainID';
import SwitchNetwork from '../../Hooks/SwitchNetwork';
import { useRouter } from 'next/router';
import UseUser from '../../Contexts/User/useUser';
import { getDeviceId } from '../../Hooks/getDeviceId';
const SellModelForNFT = (props) => {
  const router = useRouter();
  const blockChainValue = props?.details?.blockchain;
  const newTokenID = `${props?.details?.token}:${blockChainValue}?device_id=${getDeviceId()}`;
  const { hideTransactionPopup, showTransactionPopup } = UseUtil();
  const [title, setTitle] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [usdPrice, setUsdPrice] = React.useState('0.00');
  const [unlockData, setUnlockData] = React.useState('');
  const [isListing, setIslisting] = React.useState(0);
  const [nftDetails, setNftDetails] = React.useState(null);
  const [checkCreatedStatus, setCheckCreatedStatus] = React.useState(false);
  const [alt_text, setalt_text] = React.useState('');
  const [propertiesValue, setPropertiesValue] = React.useState([
    { key: '', value: '' },
  ]);
  const context = useWeb3React();
  const { library, account, chainId } = context;
  const chainIDOfNFT = getChainID(props?.details?.currency);
  const [contractAddress, setContractAddress] = React.useState();
  const [contractABIKey, setContractABIKey] = React.useState();
  const [marketPriceData, setMarketPriceData] = React.useState(null);
  const { userState, updateUserProfile } = UseUser();

  React.useEffect(() => {
    if (chainIDOfNFT) {
      let getBlockChainDataValue = blockChainData(chainIDOfNFT);
      setContractAddress(getBlockChainDataValue.contractAddress);
      setContractABIKey(getBlockChainDataValue.contractABIKey);
    }
  }, [chainIDOfNFT]);

  React.useEffect(() => {
    async function setMarketPriceDataOnLoad() {
      if (props.modalStatus === true && marketPriceData == null) {
        let marketPriceData = await getMarketPrice();
        setMarketPriceData(marketPriceData.data.data);
      }
    }
    setMarketPriceDataOnLoad();
  }, [props.modalStatus, marketPriceData]);

  const getUSDPrice = (amount) => {
    if (amount !== '' && marketPriceData !== null && props?.details?.currency) {
      let ethMarketPrice = marketPriceData.ETH;
      let maticMatketPrice = marketPriceData.MATIC;
      let priceToCalculate =
        props?.details?.currency === 'MATIC'
          ? maticMatketPrice
          : ethMarketPrice;
      let convertedUsdPrice = parseFloat(amount) * parseFloat(priceToCalculate);
      return convertedUsdPrice.toFixed(2);
    } else {
      return 0;
    }
  };

  const onChangePropertiesValue = (index, key, value) => {
    let propertyValues = [...propertiesValue];
    propertyValues[index]['key'] = key;
    propertyValues[index]['value'] = value;
    let initialValue = { key: '', value: '' };
    if (
      propertyValues[index]['key'] !== '' &&
      propertyValues[index]['value'] !== ''
    ) {
      if (propertyValues.length - 1 == index) {
        propertyValues.push(initialValue);
      }
    } else {
      propertyValues.splice(index + 1, 1);
    }
    setPropertiesValue(propertyValues);
  };
  const getNftDetailsFunc = async () => {
    const response = await getNFTDetails(newTokenID);
    if (response.status === 200) {
      setNftDetails(response.data.data);
      setTitle(response.data.data.title);
      setPrice(response.data.data.metadata.price);
      setDescription(response.data.data.description);
      setUnlockData(response.data.data.proof.unlock_data);
      setIslisting(response.data.data.metadata.is_listed);
      setCheckCreatedStatus(
        response.data.data.created_by.public_address ===
          response.data.data.owned_by.public_address
      );
      setalt_text(
        response.data.data.metadata.is_collection === 0
          ? response.data.data.asset.alt_text
          : response.data.data.asset[0].alt_text
      );
      let propertyData = [{ key: '', value: '' }];
      let propertyDataArray = response.data.data.metadata.properties.map(
        (prodata) => {
          return {
            key: prodata.key,
            value: prodata.value,
          };
        }
      );
      propertyData = [...propertyDataArray, ...propertyData];
      if (response.data.data.metadata.properties.length > 0) {
        setPropertiesValue(propertyData);
      }
    }
  };
  React.useEffect(() => {
    if (price !== null && price !== '') {
      setUsdPrice((3206.79 * parseFloat(price)).toFixed(2));
    }
  }, [price]);
  React.useEffect(() => {
    if (nftDetails === null && props.modalStatus === true) {
      getNftDetailsFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftDetails, props.modalStatus]);

  const getAndUpdateUserData = async () => {
    const userResponse = await getProfile();
    if (userResponse.status === 200) {
      updateUserProfile({
        ...userResponse?.data?.data
      });
    }
  };

  const editInitial = {
    token: nftDetails?.token ?? '',
    is_listed: isListing ?? 0,
    price: price ?? '',
    title: title ?? '',
    description: description ?? '',
    alt_text: alt_text ?? '',
    unlock_data: unlockData ?? '',
  };
  const handleOnSubmit = async (values) => {
    if (userState?.userProfileData?.wallet_type !== 'custom') {
      let requiredBlockChain =
      props?.details?.currency === 'MATIC' ? 'polygon' : 'ethereum';
    let requiredChainID =
      props?.details?.currency === 'MATIC'
        ? process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID
        : process.env.NEXT_PUBLIC_CHAIN_ID;
    if (parseInt(chainId) !== parseInt(requiredChainID)) {
      props.handleClose();
    }
    let isValidBlockChain = await SwitchNetwork(chainId, requiredBlockChain);
    if (isValidBlockChain) {
      try {
        showTransactionPopup('NFT listing in progress');
        let mycon = new Contract(
          contract[contractAddress],
          contract[contractABIKey],
          library.getSigner()
        );
        let tx = await mycon
          .listNft(editInitial.token, parseEther(editInitial.price),
          {
            gasLimit : 300000
          })
          .then(async (data) => {
            const transactionLog = await data.wait(1);
            values.tx_hash = transactionLog.transactionHash;
            values.is_listed = 1;
            values.blockchain = blockChainValue;

            let reqValues = {
              ...values,
            };
            let propertiesValueList = [...propertiesValue];
            propertiesValueList.splice(propertiesValue.length - 1, 1);
            reqValues = {
              ...reqValues,
              props: JSON.stringify(propertiesValueList),
            };
            const response = await editNftDetails(reqValues);
            if (response.status === 200) {
              // toast.success(`${response.data.message}`);
              Swal.fire({
                icon: 'success',
                title: `${response.data.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              props.updateDetails(response.data.data);
              getAndUpdateUserData();
              hideTransactionPopup();
              props.handleClose();
              if (props?.isGallery) {
                router.push('/');
              }
            } else {
              // toast.error(`${response.data.message}`);
              Swal.fire({
                icon: 'warning',
                // title: 'Oops...',
                text: `${response.data.message}`,
                timer: 1500,
              });
              hideTransactionPopup();
              props.handleClose();
            }
          });
      } catch (err) {
        console.log(err,"err");
        if (err.code == 4001) {
          Swal.fire({
            icon: 'warning',
            // title: 'Oops...',
            text: 'Transaction cancelled!',
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: 'warning',
            // title: 'Oops...',
            text: 'Your transaction is failed!',
            timer: 1500,
          });
        }
        hideTransactionPopup();
        props.handleClose();
      }
    } else {
      props.handleClose();
    }
    } else if (userState?.userProfileData?.wallet_type === 'custom') {
      let requiredBlockChain =
      props?.details?.currency === 'MATIC' ? 'polygon' : 'ethereum';
        try {
          showTransactionPopup('NFT listing in progress', ' ', ' ');
          let data = { 
            // public_address:userState?.userProfileData?.public_address,
            // private_key:userState?.userProfileData?.private_key,
            token:nftDetails?.token,
            price:price,
            blockchain:requiredBlockChain
          }
          let listResponse = await listNftTransaction(data, userState?.userProfileData?.id)
          if (listResponse.status === 200) {
            values.tx_hash = listResponse?.data?.transactionHash;
            values.is_listed = 1;
            values.blockchain = blockChainValue;

            let reqValues = {
              ...values,
            };
            let propertiesValueList = [...propertiesValue];
            propertiesValueList.splice(propertiesValue.length - 1, 1);
            reqValues = {
              ...reqValues,
              props: JSON.stringify(propertiesValueList),
            };
            const response = await editNftDetails(reqValues);
            if (response.status === 200) {
              // toast.success(`${response.data.message}`);
              Swal.fire({
                icon: 'success',
                title: `${response.data.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              props.updateDetails(response.data.data);
              getAndUpdateUserData();
              hideTransactionPopup();
              props.handleClose();
              if (props?.isGallery) {
                router.push('/');
              }
            } else {
              // toast.error(`${response.data.message}`);
              Swal.fire({
                icon: 'warning',
                // title: 'Oops...',
                text: `${response.data.message}`,
                timer: 1500,
              });
              hideTransactionPopup();
              props.handleClose();
            }
          }      
            // });
        } catch (err) {
          console.log(err,"err");
          if (err.code == 4001) {
            Swal.fire({
              icon: 'warning',
              // title: 'Oops...',
              text: 'Transaction cancelled!',
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: 'warning',
              // title: 'Oops...',
              text: 'Your transaction is failed!',
              timer: 1500,
            });
          }
          hideTransactionPopup();
          props.handleClose();
        }
    }
  };
  const cbSubmit = (values) => {
    setTitle(values.title);
    setPrice(values.price);
    setDescription(values.description);
    setIslisting(values.is_listed);
    setUnlockData(values.unlock_data);
    setalt_text(values.alt_text);
  };
  let checkCreated =
    nftDetails === null
      ? false
      : nftDetails?.created_by?.public_address ===
        nftDetails?.owned_by?.public_address;

  const { formik: editNFT } = useEditNFT(handleOnSubmit, editInitial, cbSubmit);

  const NFTPrice = editNFT?.values?.price ? editNFT?.values?.price : '';
  React.useEffect(async () => {
    if (
      props.modalStatus === true &&
      marketPriceData !== null &&
      editNFT?.values?.price !== undefined
    ) {
      let usdPrice = getUSDPrice(NFTPrice);
      setUsdPrice(usdPrice);
    }
  }, [props.modalStatus, NFTPrice, marketPriceData]);
  return (
    <div>
      <Modal
        show={props.modalStatus}
        onHide={() => {
          props.handleClose();
        }}
        className={`EditNftModel`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sell ({props.details.title})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              editNFT.handleSubmit(e);
            }}
          >
            {checkCreated === true && (
              <div className={`mb-3`}>
                <Label className={`label`} title={`Title`}></Label>
                <Input
                  type={'text'}
                  value={editNFT.values.title}
                  id="title"
                  placeHolder={`Title`}
                  onChange={(e) => {
                    editNFT.setValues((v) => {
                      return { ...v, title: e.target.value };
                    });
                  }}
                  onBlur={editNFT.handleBlur}
                  touched={editNFT.touched.title}
                  error={editNFT.errors.title}
                />
              </div>
            )}
            <div className={`mt-3 mb-0`}>
              <div className={`${classess.priceHelpText}`}>
                <Label className={`label`} title={`Price`}></Label>
                <span className={`${classess.ServiceFeeInfo}`}>
                  {props.modalStatus === true &&
                    marketPriceData !== null &&
                    `${parseFloat(usdPrice).toFixed(2)} USD`}
                </span>
              </div>
              <Input
                type={'text'}
                value={editNFT.values.price}
                id="price"
                placeHolder={`Price`}
                onChange={(e) => {
                  let numberRe = /^(\d+(\.\d{0,18})?|\.?\d{0,18})$/;
                  let check = numberRe.test(e.target.value);
                  if (check) {
                    let usdPrice = getUSDPrice(e.target.value);
                    setUsdPrice(usdPrice);
                    editNFT.setValues((v) => {
                      return { ...v, price: e.target.value };
                    });
                  }
                }}
                onBlur={editNFT.handleBlur}
                touched={editNFT.touched.price}
                error={editNFT.errors.price}
              />
            </div>
            <div className={`mt-3 mb-3`}>
              <Label
                className={`label mb-4`}
                title={`List on marketplace`}
                renderComponent={
                  <SwitchField
                    checked={true}
                    // onSwitchChange={() => {
                    //   editNFT.setValues((v) => {
                    //     return {
                    //       ...v,
                    //       is_listed: editNFT.values.is_listed === 0 ? 1 : 0,
                    //     };
                    //   });
                    // }}
                  />
                }
              />
            </div>
            <div className={`mt-3 mb-3`}>
              <Label
                className={`label`}
                title={`Unlock Once Purchased`}
                LabelinfoTextComponent={`(Optional)`}
              ></Label>
              <Input
                label={`Unlock once purchased`}
                placeHolder={`Link to a file`}
                disable={false}
                id={'unlock_data'}
                value={editNFT.values.unlock_data}
                onChange={(e) => {
                  editNFT.setValues((v) => {
                    return {
                      ...v,
                      unlock_data: e.target.value,
                    };
                  });
                }}
              />
            </div>
            {checkCreated === true && (
              <div className={`mt-3 mb-3`}>
                <Label
                  className={`label`}
                  title={`Description`}
                  LabelinfoTextComponent={`(Optional)`}
                ></Label>
                <TextArea
                  label={`Description`}
                  placeHolder={`Eg: Savage Dogs is the art of Martin Bekerman, a creative from Buenos Aires, based in California.`}
                  id={'description'}
                  rows={3}
                  value={editNFT.values.description}
                  onChange={(e) => {
                    editNFT.setValues((v) => {
                      return {
                        ...v,
                        description: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            )}
            {checkCreated === true && (
              <div className={`mt-3 mb-3`}>
                <Label
                  LabelinfoTextComponent={`(Optional)`}
                  className={`label mr-2`}
                  title={`Properties`}
                />
                {propertiesValue.map((data, index) => {
                  return (
                    <Row key={index}>
                      <Col>
                        <Input
                          placeHolder={`Eg. size`}
                          value={data.key}
                          onChange={(event) => {
                            onChangePropertiesValue(
                              index,
                              event.target.value,
                              data.value
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <Input
                          placeHolder={`Eg. M`}
                          value={data.value}
                          onChange={(event) => {
                            onChangePropertiesValue(
                              index,
                              data.key,
                              event.target.value
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </div>
            )}

          {userState?.userProfileData?.wallet_type === 'custom' &&
          <span className={`${styles.gasfeetext}`}>Please maintain your Wallet balance, max process fee of {blockChainValue === 'ethereum' ? '0.007 ETH' : '0.05 MATIC'}  will be charged!</span>
              }
            <div className={`d-flex justify-content-end`}>
              <Button className={`mr-2`} type="submit">
                {'List on Marketplace'}
              </Button>
              <Button
                className={``}
                varient={'secondary_nooutline'}
                onClick={() => {
                  props.handleClose();
                  setNftDetails(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SellModelForNFT;
